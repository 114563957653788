import React from "react"
import Layout from '../components/template/Layout'
import { Container } from '@material-ui/core'

export default function ContactOk() {
  return (
    <Layout title="Merci pour votre message !" description="Confirmation de l'envoi de mail" type="Contact Ok" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        Un email vient de nous être envoyé. <br/>
        Nous y répondrons dans les plus bref délais.
      </Container>
    </Layout>
  );
}

